import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = { userAnswerList: [] };

const actions = {
  getUserAnswer: ({ commit }, caseId) => {
    axios({
      method: "get",
      url: BASE_URL + "api/list-user-answer/" + caseId + "/",
    })
      .then((resp) => commit("setUserAnswer", resp.data))
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setUserAnswer: (state, resp) => {
    state.userAnswerList = resp;
  },
};

export default {
  state,
  actions,
  mutations,
};
