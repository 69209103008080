<template>
  <div class="img-container"></div>
</template>

<script>
export default {};
</script>

<style>
.img-container {
  width: 250px;
  height: 100%;
  background-color: #7957d5;
  background-image: url("../assets/left-image.png");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  z-index: 999;
}
</style>
