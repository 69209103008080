<template>
  <div class="question-list-container">
    <div class="breadcrumbs">
      Back to
      <img src="../assets/arrow.png" /><span class="breadcrumbs-2">
        <router-link to="/assignment" exact>
          Assignment
        </router-link>
      </span>
    </div>
    <div class="fs24 bold">Slide Case</div>
    <div v-if="slideList.length == 0"><Spinner /></div>
    <div
      v-for="list in slideList"
      :key="list.id"
      class="question-item"
      @click="handleClickSlide(list.id)"
    >
      <img :src="list.label" class="img-thumbnail" />
      <div class="question-item-desc">
        <div class="fs16">#{{ list.id }}</div>
        <p class="fs12">Check the image, and answer the questions</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../components/Spinner";

export default {
  props: ["state"],
  data() {
    return {
      caseId: this.$route.params.case_id,
    };
  },
  components: {
    Spinner,
  },
  methods: {
    handleClickSlide: function(id) {
      this.state.selectedSlide = id;
    },
  },
  computed: mapState({
    slideList: (state) => state.slide.slideList,
  }),
  created() {
    this.$store.dispatch("getSlide", this.caseId);
  },
};
</script>

<style scoped>
.question-list-container {
  width: 85%;
  margin: 20px 10px 0 20px;
}

.breadcrumbs {
  font-size: 11px;
  color: #aaaaaa;
  display: flex;
  align-items: center;
  margin: 50px 0 20px 0;
}

.breadcrumbs-2 {
  color: #7957d5;
}

.question-item {
  display: flex;
  margin-top: 20px;
  cursor: pointer;
}

.question-item-desc {
  margin-left: 10px;
}

.img-thumbnail {
  width: 80px;
  height: 80px;
}
</style>
