import axios from "axios";

const state = {
  status: "",
  token: localStorage.getItem("user-token") || "",
};

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: "https://api.neurabot.ai/api/v1/auth/login/",
        data: user,
        method: "POST",
      })
        .then((resp) => {
          const token = resp.data.token;
          const user = resp.data.user;
          const usernames = resp.data.user.username;
          localStorage.setItem("user-token", token);
          localStorage.setItem("user-data", usernames);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          commit("auth_success", token, user);
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("user-token");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    localStorage.removeItem("user-token");
    commit("is_logout");
  },
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token, user) {
    state.status = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.status = "error";
  },
  is_logout(state) {
    state.status = "";
    state.token = "";
  },
};
const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
