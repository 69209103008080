<template>
  <div class="image-view-container">
    <div v-if="deck == null" class="spinner"><Spinner /></div>
    <canvas v-if="isShowCanvas" id="my-canvas" class="image-viewer"></canvas>
    <div class="view-finder" :style="viewFinderStyle">
      <div class="lens-container">
        <div class="lens" :style="lensStyle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Deck,
  OrthographicView,
  COORDINATE_SYSTEM,
  LayerExtension,
} from "@deck.gl/core";
import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer, PolygonLayer, TextLayer } from "@deck.gl/layers";
import { load } from "@loaders.gl/core";
import { clamp } from "math.gl";
import Spinner from "../components/Spinner";
import { mapState } from "vuex";

const N_POLYGON = 32; // Annotation = reguler N-polygon
const ANNOT_WIDTH = 3; // Annotation default width

export default {
  props: [
    "onTilesLoad",
    // "viewState",
    "state",
    "annotColor",
    "imageUrl",
    "clickDelete",
    "thumbnailUrl",
    "slideId",
    "brightness",
    "contrast",
    "zoomSize",
  ],
  components: { Spinner },
  data() {
    return {
      zoomInitial: -2,
      viewStates: {
        target: [1800, 800],
        zoom: -2,
      },
      RADIUS: null, // Annotation radius,
      triggerFilter: true,
      triggerReload: true,
      circleTemplateCoordinate: null,
      layerTile: null,
      layerAnnot: null,
      layerCounter: null,
      deck: null,
      changeWidth: false,
      changeColor: false,
      dimensions: null,
      selectedAnnot: [],
      zoomImg: 0,
      boxLeft: 0, // margin left viewfinder
      boxTop: 0, // margin top viewfinder
      boxWidth: 0, // lebar viewfinder
      boxHeight: 0, // tinggi viewfinder
      postProcessEffect: null,
      viewState: {
        target: [1800, 800],
        zoom: -2,
      },
      isShowCanvas: true,
      layerExten: null,
      redFilters: null,
      imageMaxZoom: 8,
      zData: 0,
      // this first annotation data is declared but ignored
      annotData: [
        {
          id: null,
          color: [0, 0, 0, 0],
          polygon: [[[0, 0]]],
          lineWidth: 0,
          center: [-99999, -99999],
        },
      ],
      counterFlag: {},
    };
  },

  computed: {
    ...mapState({
      annotationList: (state) => state.annotation.annotationList,
    }),
    lensStyle: function() {
      // lens = kotak merah pada viewfinder
      // margin viewfinder dibuat di range antara 1-198 karena view finder box nya beukuran 200x200
      let leftMargin = Math.max(0, Math.min(this.boxLeft, 198));
      let topMargin = Math.max(0, Math.min(this.boxTop, 198));
      return (
        "left:" +
        leftMargin +
        "px;" +
        "top:" +
        topMargin +
        "px;" +
        "width:" +
        Math.max(0, Math.min(this.boxWidth, 198 - leftMargin)) +
        "px;" +
        "height:" +
        Math.max(0, Math.min(this.boxHeight, 198 - topMargin)) +
        "px;"
      );
    },
    viewFinderStyle: function() {
      return "background-image:url(" + this.thumbnailUrl + ")";
    },
    counterData: function() {
      let counterDatas = this.annotData.map((item) => {
        let colors = item.color.toString();
        // counterFlag digunakan untuk menandai counternya sudah sampai angka berapa
        if (!this.counterFlag[colors]) this.counterFlag[colors] = 1;
        else this.counterFlag[colors]++;
        return {
          text: this.counterFlag[colors].toString(),
          position: item.center,
        };
      });
      return counterDatas;
    },
  },

  methods: {
    // There is no circle in deck.gl, so we create regular N-polygon to make annotation.
    // The function circleTemplate() only called once
    circleTemplate() {
      let coordinate = [];
      for (let i = 0; i < N_POLYGON; i++) {
        coordinate.push([
          this.RADIUS * Math.sin((i * Math.PI * 2) / N_POLYGON),
          this.RADIUS * Math.cos((i * Math.PI * 2) / N_POLYGON),
        ]);
      }
      return coordinate;
    },
    // circle with center (a,b)
    getCircle(a, b) {
      let circleCoordinate = [];
      for (let i = 0; i < N_POLYGON; i++) {
        circleCoordinate.push([
          a + this.circleTemplateCoordinate[i][0],
          b - this.circleTemplateCoordinate[i][1],
        ]);
      }
      return circleCoordinate;
    },

    handleCreateAnnot(e) {
      this.deck.viewState = {
        target: [1800, 800],
        zoom: 0,
      };
      if (this.state.enableCreate) {
        const a = e.coordinate[0];
        const b = e.coordinate[1];
        this.annotData = this.annotData.concat({
          color: this.annotColor,
          polygon: this.getCircle(a, b),
          lineWidth: ANNOT_WIDTH,
          center: [a, b],
        });
        this.renderAnnotLayer();
        this.$store.dispatch("createAnnotation", {
          slideId: this.slideId,
          coordinateX: a,
          coordinateY: b,
          color: this.annotColor,
        });
      }
    },

    async handleViewStateChange(e) {
      this.viewState = e.viewState;
      this.deck.setProps({
        viewState: e.viewState,
      });
      // Mengontrol ukuran lens pada viewfinder
      this.state.zoomVal = e.viewState.zoom;
      this.viewStates = e.viewState;
      let canvasWidth = this.deck.canvas.width;
      let canvasHeight = this.deck.canvas.height;
      let targetX = canvasWidth / 2;
      let targetY = canvasHeight / 2;
      this.zoomImg = e.viewState.zoom;
      let originalHeight = this.dimensions.height;
      let originalWidth = this.dimensions.width;

      let imgHeight = 1280 * Math.pow(2, this.zoomImg);
      let imgWidth = imgHeight * (originalWidth / originalHeight);
      if (originalHeight >= originalWidth) {
        this.boxLeft =
          ((e.viewState.target[0] * Math.pow(2, this.zoomImg) - targetX) *
            200) /
          imgHeight;
        this.boxTop =
          ((e.viewState.target[1] * Math.pow(2, this.zoomImg) - targetY + 56) *
            200) /
          imgHeight;
      } else {
        this.boxLeft =
          ((e.viewState.target[0] * Math.pow(2, this.zoomImg) - targetX) *
            200) /
          imgWidth;
        this.boxTop =
          ((e.viewState.target[1] * Math.pow(2, this.zoomImg) - targetY + 56) *
            200) /
          imgWidth;
      }

      if (originalHeight >= originalWidth) {
        this.boxWidth = (window.innerWidth * 0.6 * 200) / imgHeight;
        this.boxHeight = ((canvasHeight - 56) * 200) / imgHeight;
      } else {
        this.boxWidth = (window.innerWidth * 0.6 * 200) / imgWidth;
        this.boxHeight = ((canvasHeight - 56) * 200) / imgWidth;
      }
    },

    handleSelectAnnot(e) {
      this.selectedAnnot[e.index] = !this.selectedAnnot[e.index]; // Toggle Select Annotation
      const selected = this.annotData[e.index];
      this.changeWidth = !this.changeWidth;
      this.changeColor = !this.changeColor;

      selected.lineWidth = this.selectedAnnot[e.index]
        ? ANNOT_WIDTH + 4
        : ANNOT_WIDTH;

      this.renderAnnotLayer();
    },

    changeAnnotColor() {
      this.annotData = this.annotData.map((item, index) => {
        return {
          lineWidth: ANNOT_WIDTH,
          polygon: item.polygon,
          color: this.selectedAnnot[index] ? this.annotColor : item.color,
          center: item.center,
        };
      });
      this.renderAnnotLayer();
      this.diselectAllAnnot();
    },

    async deleteAnnot() {
      let deleteList = await this.annotData
        .filter((item, index) => {
          this.selectedAnnot[index];
        })
        .map((item) => item.id);
      this.annotData = await this.annotData.filter(
        (_, i) => !this.selectedAnnot[i]
      );
      this.renderAnnotLayer();
      this.diselectAllAnnot();
      setTimeout(() => {
        this.$store.dispatch("deleteAnnotation", deleteList);
      }, 1000);
    },

    diselectAllAnnot() {
      for (let i in this.selectedAnnot) this.selectedAnnot[i] = false;
    },

    renderCounterLayer() {
      this.counterFlag = {};
      this.layerCounter = new TextLayer({
        id: "text-layer",
        data: this.counterData,
        pickable: true,
        updateTriggers: {
          getPosition: this.counterData,
          getText: this.counterData,
        },
        getSize: 2,
        sizeUnits: "meters",
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
      });
    },

    renderAnnotLayer() {
      this.layerAnnot = new PolygonLayer({
        id: "polygon-layer",
        data: this.annotData,
        onClick: (e) => {
          this.handleSelectAnnot(e);
        },
        pickable: true,
        updateTriggers: {
          getPolygon: this.annotData,
          getLineWidth: this.changeWidth,
          getLineColor: this.changeColor,
        },
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        stroked: true,
        filled: true,
        lineWidthUnits: "pixels",
        wireframe: true,
        lineJointRounded: true,
        lineWidthMinPixels: 1,
        getPolygon: (d) => d.polygon,
        getFillColor: [0, 0, 0, 0],
        getLineColor: (d) => d.color,
        getLineWidth: (d) => d.lineWidth,
      });
      this.renderCounterLayer();
      this.deck.setProps({
        layers: [this.layerTile, this.layerAnnot, this.layerCounter],
      });
    },

    redraw(contrastParam, brightnessParam) {
      this.triggerFilter = !this.triggerFilter;
      this.redFilters = class RedFilter extends LayerExtension {
        getShaders() {
          return {
            inject: {
              "fs:#decl": `
        uniform float brightness;
        uniform float contrast;`,

              "fs:DECKGL_FILTER_COLOR": `
        color.rgb += brightness;
        if (contrast > 0.0) {
          color.rgb = (color.rgb - 0.5) / (1.0 - contrast) + 0.5;
        } else {
          color.rgb = (color.rgb - 0.5) * (1.0 + contrast) + 0.5;
        }
        `,
            },
          };
        }

        updateState(params) {
          const {
            contrast = contrastParam,
            brightness = brightnessParam,
          } = params.props;
          for (const model of this.getModels()) {
            model.setUniforms({
              contrast,
              brightness,
            });
          }
        }

        getSubLayerProps(params) {
          const {
            contrast = contrastParam,
            brightness = brightnessParam,
          } = params.props;
          return {
            contrast,
            brightness,
          };
        }
      };
      (this.layerTile = new TileLayer({
        onClick: (e) => this.handleCreateAnnot(e),
        pickable: true,
        tileSize: this.dimensions.tileSize,
        highlightColor: [60, 60, 60, 100],
        minZoom: 0,
        maxZoom: 8,

        triggerFilters: this.triggerFilter,
        onTileError: () => {
          if (this.imageMaxZoom > 2) {
            this.deck.setProps({
              viewState: {
                target: this.viewState.target,
                zoom: this.viewState.zoom,
              },
            });
            this.redraw2(0, 0);
          }
        },

        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        extent: [0, 0, this.dimensions.width, this.dimensions.height],
        getTileData: ({ x, y, z }) => {
          this.imageMaxZoom = z;
          return load(
            `${this.imageUrl.replace(".dzi", "_files")}/${10 +
              z}/${x}_${y}.jpeg`
          );
        },
        onViewportLoad: this.onTilesLoad,
        renderSubLayers: (props) => {
          const {
            bbox: { left, bottom, right, top },
          } = props.tile;
          const { width, height } = this.dimensions;
          let rights = right;
          let bottoms = bottom;
          if (props.data) {
            rights = left + ((props.data.width + 1) / 256) * (right - left);
            bottoms = top + ((props.data.height + 1) / 256) * (bottom - top);
          }
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            extensions: [new this.redFilters()],

            bounds: [
              clamp(left, 0, width),
              clamp(bottoms, 0, height),
              clamp(rights, 0, width),
              clamp(top, 0, height),
            ],
          });
        },
      })),
        setTimeout(() => {
          this.deck.setProps({
            layers: [this.layerTile, this.layerAnnot, this.layerCounter],
          });
        }, 100);
    },

    redraw2(contrastParam, brightnessParam) {
      this.triggerFilter = !this.triggerFilter;
      this.redFilters = class RedFilter extends LayerExtension {
        getShaders() {
          return {
            inject: {
              "fs:#decl": `
        uniform float brightness;
        uniform float contrast;`,

              "fs:DECKGL_FILTER_COLOR": `
        color.rgb += brightness;
        if (contrast > 0.0) {
          color.rgb = (color.rgb - 0.5) / (1.0 - contrast) + 0.5;
        } else {
          color.rgb = (color.rgb - 0.5) * (1.0 + contrast) + 0.5;
        }
        `,
            },
          };
        }

        updateState(params) {
          const {
            contrast = contrastParam,
            brightness = brightnessParam,
          } = params.props;
          for (const model of this.getModels()) {
            model.setUniforms({
              contrast,
              brightness,
            });
          }
        }

        getSubLayerProps(params) {
          const {
            contrast = contrastParam,
            brightness = brightnessParam,
          } = params.props;
          return {
            contrast,
            brightness,
          };
        }
      };
      (this.layerTile = new TileLayer({
        onClick: (e) => this.handleCreateAnnot(e),
        pickable: true,
        tileSize: this.dimensions.tileSize,
        highlightColor: [60, 60, 60, 100],
        minZoom: 0,
        maxZoom: this.imageMaxZoom - 1,
        triggerFilters: this.triggerFilter,
        onTileError: () => {},

        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        extent: [0, 0, this.dimensions.width, this.dimensions.height],
        getTileData: ({ x, y, z }) => {
          return load(
            `${this.imageUrl.replace(".dzi", "_files")}/${10 +
              z}/${x}_${y}.jpeg`
          );
        },
        onViewportLoad: this.onTilesLoad,
        renderSubLayers: (props) => {
          const {
            bbox: { left, bottom, right, top },
          } = props.tile;
          const { width, height } = this.dimensions;
          let rights = right;
          let bottoms = bottom;
          if (props.data) {
            rights = left + ((props.data.width + 1) / 256) * (right - left);
            bottoms = top + ((props.data.height + 1) / 256) * (bottom - top);
          }
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            extensions: [new this.redFilters()],

            bounds: [
              clamp(left, 0, width),
              clamp(bottoms, 0, height),
              clamp(rights, 0, width),
              clamp(top, 0, height),
            ],
          });
        },
      })),
        setTimeout(() => {
          this.deck.setProps({
            layers: [this.layerTile, this.layerAnnot, this.layerCounter],
          });
        }, 100);
    },

    async renderImageLayer() {
      this.layerTile = new TileLayer({
        onClick: (e) => this.handleCreateAnnot(e),
        pickable: true,
        tileSize: this.dimensions.tileSize,
        highlightColor: [60, 60, 60, 100],
        minZoom: 0,
        maxZoom: 8,
        triggerReload: this.triggerReload,

        onTileError: () => {
          // Jika gambar sudah mencapai zoom limit, maka Layer akan dirender ulang dengan dengan max zoom yang terbesar
          if (this.imageMaxZoom > 2) {
            this.deck.setProps({
              viewState: {
                target: this.viewState.target,
                zoom: this.viewState.zoom,
              },
            });
            this.redraw2(0, 0);
          }
        },
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        extent: [0, 0, this.dimensions.width, this.dimensions.height],
        getTileData: ({ x, y, z }) => {
          this.imageMaxZoom = z;
          return load(
            `${this.imageUrl.replace(".dzi", "_files")}/${10 +
              z}/${x}_${y}.jpeg`
          );
        },
        onViewportLoad: this.onTilesLoad,
        renderSubLayers: (props) => {
          const {
            bbox: { left, bottom, right, top },
          } = props.tile;

          let rights = right;
          let bottoms = bottom;
          if (props.data) {
            rights = left + ((props.data.width + 1) / 256) * (right - left);
            bottoms = top + ((props.data.height + 1) / 256) * (bottom - top);
          }

          const { width, height } = this.dimensions;

          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            highlightRed: true,

            bounds: [
              clamp(left, 0, width),
              clamp(bottoms, 0, height),
              clamp(rights, 0, width),
              clamp(top, 0, height),
            ],
          });
        },
      });
      this.deck = new Deck({
        canvas: "my-canvas",
        initialViewState: this.viewState,
        viewState: this.viewState,
        controller: true,
        views: [new OrthographicView({ id: "ortho" })],
        onViewStateChange: (e) => this.handleViewStateChange(e),
        layers: [this.layerTile],
      });
    },
  },

  mounted() {
    const getMetaData = async () => {
      const dziSource = this.imageUrl; // the .dzi image
      const response = await fetch(dziSource);
      const xmlText = await response.text();
      const dziXML = new DOMParser().parseFromString(xmlText, "text/xml");
      if (
        Number(
          dziXML.getElementsByTagName("Image")[0].attributes.Overlap.value
        ) !== 0
      ) {
        // eslint-disable-next-line no-undef, no-console
        console.warn("Overlap parameter is nonzero and should be 0");
      }
      this.dimensions = {
        height: Number(
          dziXML.getElementsByTagName("Size")[0].attributes.Height.value
        ),
        width: Number(
          dziXML.getElementsByTagName("Size")[0].attributes.Width.value
        ),
        tileSize: Number(
          dziXML.getElementsByTagName("Image")[0].attributes.TileSize.value
        ),
      };
      this.RADIUS = 3;
      this.circleTemplateCoordinate = this.circleTemplate();
    };
    getMetaData();
  },
  created() {
    this.$store.dispatch("getAnnotation", this.slideId);
  },
  watch: {
    viewState: function() {
      this.viewStates = this.viewState;
    },
    clickDelete: function() {
      this.deleteAnnot();
    },
    annotColor: function() {
      this.changeAnnotColor();
      this.renderCounterLayer();
    },
    annotationList: function() {
      const initialAnnotData = this.annotationList
        .find((i) => i.slideId == this.slideId)
        .annot.map((item) => ({
          id: item.id,
          color: item.annot.color,
          center: item.annot.center,
          polygon: this.getCircle(item.annot.center[0], item.annot.center[1]),
          lineWidth: 3,
        }));
      this.annotData = initialAnnotData;
      this.renderAnnotLayer();
    },
    dimensions: function() {
      // image layer can be rendered only after dimensions has already computed
      this.viewState = {
        target: [1800, 800],
        zoom: this.zoomInitial,
      };
      setTimeout(() => {
        // dibuat settimeout supaya codenya tidak balapan
        this.renderImageLayer();
      }, 1000);
      setTimeout(() => {
        // dibuat settimeout supaya codenya tidak balapan
        // render annotation twice, because render it once doesn't work, i don't know why. haha
        this.renderAnnotLayer();
        this.renderAnnotLayer();
      }, 2000);
    },
    contrast: function() {
      this.redraw(this.contrast, this.brightness);
    },
    brightness: function() {
      this.redraw(this.contrast, this.brightness);
    },
    zoomSize: function() {
      // this.redraw(this.contrast, this.brightness);
      this.deck.setProps({
        viewState: {
          target: this.viewState.target,
          zoom: Math.log2(this.zoomSize) - 2,
        },
      });
    },
  },
};
</script>

<style scoped>
.spinner {
  margin-top: 200px;
}
.lens-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.lens {
  position: absolute;
  border: 1px solid red;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}
.image-viewer {
  height: 100%;
  width: 60%;
}
.image-view-container {
  width: 100%;
}
.view-finder {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 0px;
  /* left: 50px; */
  background-color: #fff;
  border: 1px solid black;
  z-index: 39;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
