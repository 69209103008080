import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = { diseaseList: [] };

const actions = {
  getDisease: ({ commit }, param) => {
    commit("resetDisease");
    axios({
      method: "get",
      url: BASE_URL + "api/list-diseases/",
      params: {
        assigment_id: param.assignmentId,
      },
    })
      .then((resp) => commit("setDisease", resp.data))
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  resetDisease: (state) => {
    state.diseaseList = [];
  },
  setDisease: (state, resp) => {
    state.diseaseList = resp;
  },
};

export default {
  state,
  actions,
  mutations,
};
