<template>
  <div class="containers">
    <LeftImage />
    <div class="assign-container">
      <MenuIcon />
      <div class="fs16 name-section">Hi, {{ username }}</div>
      <div class="assign-section">
        You have
        <span class="assign-length"> {{ assignmentList.length }}</span>
        assignments
      </div>
      <div class="fs30 bold assign-title">
        Assignment ({{ assignmentList.length }})
      </div>
      <div class="info-section">
        <img src="../assets/danger-icon.png" class="danger-icon" /> Select the
        Assignment from the list below
      </div>
      <div v-if="assignmentList.length == 0" class="spinner"><Spinner /></div>
      <div v-for="list in assignmentList" :key="list.name">
        <router-link :to="setRouter(list)" exact>
          <b-button type="is-primary" outlined class="assign-box">
            <div
              class="assign-item"
              :style="[list.status === 'completed' ? { color: '#bdbdbd' } : {}]"
            >
              <div class="fs20 bold assign-name">
                {{ list.name }}
                <span class="assign-status"> [ {{ list.status }} ] </span>
              </div>
              <div class="fs12 assign-participant">
                {{ list.participant.length }} peserta
              </div>
              <div class="fs12 assign-date">
                {{ showDate(new Date(list.start_duration)) }} ~
                {{ showDate(new Date(list.end_duration)) }}
              </div>
            </div>
          </b-button></router-link
        >
      </div>
    </div>
    <div class="footers">Powered by Neurabot</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../components/Spinner";
import MenuIcon from "../components/MenuIcon";
import LeftImage from "../components/LeftImage";

export default {
  components: {
    LeftImage,
    Spinner,
    MenuIcon,
  },
  data() {
    return {
      username: "",
    };
  },
  computed: {
    ...mapState({
      assignmentList: (state) => state.assignment.assignmentList,
    }),
  },
  methods: {
    setRouter: function(list) {
      return list.status !== "panding" ? `/assignment/${list.id}` : "";
    },
    showDate: function(date) {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${days[date.getDay()]}, ${date.getDate()} ${
        months[date.getMonth()]
      } ${date.getFullYear()}`;
    },
  },
  created() {
    this.$store.dispatch("getAssignment");
    this.username = localStorage.getItem("user-data");
  },
};
</script>

<style scoped>
.assign-status {
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
}

.footers {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 0;
  position: fixed;
  background-color: #3a4659;
  color: white;
  font-size: 12px;
  padding-left: 20px;
  z-index: 1000;
}

.info-section {
  background-color: #ebebeb;
  width: 780px;
  height: 39px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #a6a6a6;
  padding-left: 20px;
}

.assign-length {
  color: #7957d5;
}

.danger-icon {
  margin-right: 10px;
}

.name-section {
  color: #7957d5;
}

.assign-section {
  width: 780px;
  font-size: 30px;
}

.containers {
  width: 100%;
  height: 100%;
}

.assign-container {
  padding-left: 320px;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: scroll;
}

.assign-title {
  width: 780px;
  font-weight: 300;
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.assign-participant {
  width: 100px;
  height: 20px;
  font-weight: 400;
  margin-right: 50px;
  border-radius: 10px;
  background-color: #f46a6a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assign-box {
  width: 780px;
  height: 58px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  font-weight: 300;
}

.assign-name {
  width: 300px;
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.assign-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 0;
}

.spinner {
  width: 780px;
  display: flex;
  justify-content: center;
}

.assign-date {
  font-weight: 400;
}
</style>
