import { render, staticRenderFns } from "./SlideList.vue?vue&type=template&id=073e1cd6&scoped=true&"
import script from "./SlideList.vue?vue&type=script&lang=js&"
export * from "./SlideList.vue?vue&type=script&lang=js&"
import style0 from "./SlideList.vue?vue&type=style&index=0&id=073e1cd6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073e1cd6",
  null
  
)

export default component.exports