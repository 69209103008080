import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = { assignmentList: [] };

const actions = {
  getAssignment: ({ commit }) => {
    axios({
      method: "get",
      url: BASE_URL + "api/list-assigment/",
    })
      .then((resp) => commit("setAssignment", resp.data))
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setAssignment: (state, resp) => {
    state.assignmentList = resp;
  },
};

export default {
  state,
  actions,
  mutations,
};
