<template>
  <b-dropdown
    :triggers="['hover']"
    aria-role="list"
    position="is-bottom-left"
    class="dropdown-icon"
  >
    <template #trigger>
      <div class="dd-icon"><img src="../assets/burger.svg" /></div>
    </template>
    <b-dropdown-item aria-role="listitem" class="dropdown-content2">
      <img class="dd-image" src="../assets/uil_setting.png" />
      Edit Profile</b-dropdown-item
    >
    <b-dropdown-item
      aria-role="listitem"
      @click="handleLogout"
      class="dropdown-content2"
    >
      <img class="dd-image" src="../assets/tabler_logout.png" />
      Logout</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  methods: {
    handleLogout: function() {
      this.$store.dispatch("logout").then(() => this.$router.push("/login"));
    },
  },
};
</script>

<style>
.dropdown-icon {
  margin-top: 33px;
  width: 100%;
  right: 30px;
  display: flex;
  justify-content: flex-end;
}

.dropdown-content2 {
  display: flex;
  align-items: center;
}

.dropdown-content2 {
  display: flex;
  align-items: center;
}

.dd-image {
  margin-right: 10px;
}
</style>
