<template>
  <div class="spinner-container">
    <svg
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.0"
      width="32px"
      height="32px"
      viewBox="0 0 128 128"
      xml:space="preserve"
    >
      <g>
        <circle cx="16" cy="64" r="16" fill="#9e9e9e" fill-opacity="1" />
        <circle
          cx="16"
          cy="64"
          r="14.344"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(45 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="12.531"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(90 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="10.75"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(135 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="10.063"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(180 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="8.063"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(225 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="6.438"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(270 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="5.375"
          fill="#9e9e9e"
          fill-opacity="1"
          transform="rotate(315 64 64)"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
          calcMode="discrete"
          dur="480ms"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
.spinner-container {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
