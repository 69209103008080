<template>
  <div class="toolbar" id="toolbar">
    <div class="toolbar-icon-container">
      <Counter
        :iconColor="counterIcon.color"
        :background="counterIcon.background"
        @click.native="handleClickCounter"
      />
      <div class="toolbar-color-palette">
        <label :style="colorPickerStyle">
          <input
            type="color"
            value="#4caf50"
            v-model="selectedColor"
            class="inputColor"
          />
        </label>
      </div>
      <EyeActive
        @click.native="handleClickEye"
        :iconColor="eyeActiveIcon.color"
        :background="eyeActiveIcon.background"
      />
      <img src="../assets/Vector.png" class="toolbar-icon" />
      <Trash
        @click.native="handleClickTrash"
        :iconColor="trashIcon.color"
        :background="trashIcon.background"
      />
    </div>
    <div class="toolbar-zoom">
      zoom
      <div class="zoom-box">
        {{ zoomValNormalized }}
      </div>
      <select v-model="selectedZoom">
        <option value="0">
          {{
            (parseInt(this.selectedZoom) !== 1 &&
              parseInt(this.selectedZoom) !== 2 &&
              parseInt(this.selectedZoom) !== 4 &&
              parseInt(this.selectedZoom) !== 10 &&
              parseInt(this.selectedZoom) !== 20 &&
              parseInt(this.selectedZoom) !== 40 &&
              zoomValNormalized) ||
              ""
          }}
        </option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="4"> 4</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="40">40</option>
      </select>
      <QuestionIcon
        @click.native="handleClickQuestIcon"
        :iconColor="questionIcon.color"
        :background="questionIcon.background"
      />
    </div>
  </div>
</template>

<script>
import Counter from "../icons/Counter";
import EyeActive from "../icons/EyeActive";
import Trash from "../icons/Trash";
import QuestionIcon from "../icons/QuestionIcon";

export default {
  props: { state: Object, showRightSidebars: Boolean, zoomVal: Number },
  components: {
    Counter,
    EyeActive,
    Trash,
    QuestionIcon,
  },
  data() {
    return {
      isShowColorPalette: false,
      ActiveIconColor: "#F2C94C",
      ActiveIconBackground: "#526076",
      selectedColor: "#4caf50",
      isCounterActive: false,
      zoomByScroll: true,
      selectedZoom: 0,
      counterIcon: {
        color: "white",
        background: "",
      },
      eyeActiveIcon: {
        color: "white",
        background: "",
      },
      trashIcon: {
        color: "white",
        background: "",
      },
      questionIcon: {
        color: "#F2C94C",
        background: "",
      },
    };
  },
  computed: {
    colorPickerStyle() {
      return "background-color: " + this.selectedColor;
    },
    zoomValue: {
      get: function() {
        if (this.zoomByScroll) return this.zoomVal;
        else return Math.log2(parseInt(this.selectedZoom)) - 2;
      },
    },
    zoomValNormalized() {
      return Math.floor(Math.pow(2, this.zoomValue + 2) * 10) / 10;
    },
  },
  methods: {
    handleClickQuestIcon: function() {
      if (this.showRightSidebars) {
        if (this.state.showEnhance) {
          this.questionIcon.color = this.ActiveIconColor;
          this.questionIcon.background = this.ActiveIconBackground;
          this.eyeActiveIcon.color = "white";
          this.eyeActiveIcon.background = "";
          this.state.showEnhance = false;
        } else {
          this.questionIcon.color = "white";
          this.questionIcon.background = "";
          this.$emit("showRightBar", false);
        }
      } else {
        this.questionIcon.color = this.ActiveIconColor;
        this.questionIcon.background = this.ActiveIconBackground;
        this.$emit("showRightBar", true);
        this.state.showEnhance = false;
      }
    },
    handleClickCounter: function() {
      this.state.enableCreate = !this.state.enableCreate;
      this.isCounterActive = !this.isCounterActive;
      this.counterIcon.color = this.isCounterActive
        ? this.ActiveIconColor
        : "white";
      this.counterIcon.background = this.isCounterActive
        ? this.ActiveIconBackground
        : "";
    },
    showColorPalette: function() {
      this.isShowColorPalette = !this.isShowColorPalette;
    },
    handleClickEye: function() {
      if (this.showRightSidebars) {
        if (this.state.showEnhance) {
          this.eyeActiveIcon.color = "white";
          this.eyeActiveIcon.background = "";
          this.$emit("showRightBar", false);
        } else {
          this.eyeActiveIcon.color = this.ActiveIconColor;
          this.eyeActiveIcon.background = this.ActiveIconBackground;
          this.questionIcon.color = "white";
          this.questionIcon.background = "";
          this.state.showEnhance = true;
        }
      } else {
        this.eyeActiveIcon.color = this.ActiveIconColor;
        this.eyeActiveIcon.background = this.ActiveIconBackground;
        this.state.showEnhance = true;
        this.$emit("showRightBar", true);
      }
    },
    handleClickTrash: function() {
      this.state.clickDelete = !this.state.clickDelete;
      this.trashIcon.color = this.ActiveIconColor;
      this.trashIcon.background = this.ActiveIconBackground;
      setTimeout(() => {
        this.trashIcon.color = "white";
        this.trashIcon.background = "";
      }, 1000);
    },
    hexToRgb: function(h) {
      let r = "0x" + h[1] + h[2];
      let g = "0x" + h[3] + h[4];
      let b = "0x" + h[5] + h[6];
      return [+r, +g, +b, 200];
    },
    setColor: function() {
      this.state.annotColor = this.hexToRgb(this.selectedColor);
    },
  },
  watch: {
    selectedColor: function() {
      this.setColor();
    },
    zoomVal: function() {
      this.zoomByScroll = true;
      if (
        this.zoomVal !== 1 &&
        this.zoomVal !== 2 &&
        this.zoomVal !== 4 &&
        this.zoomVal !== 10 &&
        this.zoomVal !== 20 &&
        this.zoomVal !== 40
      ) {
        this.selectedZoom = 0;
      }
    },
    selectedZoom: function() {
      if (this.selectedZoom !== 0) {
        this.zoomByScroll = false;
        this.$emit("changeZoom", parseInt(this.selectedZoom));
      }
    },
    showRightSidebars: function() {
      if (!this.showRightSidebars) {
        this.eyeActiveIcon.color = "white";
        this.eyeActiveIcon.background = "";
        this.questionIcon.color = "white";
        this.questionIcon.background = "";
      }
    },
  },
};
</script>

<style scoped>
label {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  margin: 5px;
}

.inputColor {
  visibility: hidden;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 60%;
  height: 56px;
  position: fixed;
  align-items: center;
  background: #3a4659;
  z-index: 999;
  transition: 0.5s;
}
.toolbar-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.toolbar-zoom {
  margin-right: 20px;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-button {
  background: #3a4659;
  color: white;
  border: none;
  font-size: 14px;
}

.zoom-button:active {
  border: none;
}

.zoom-button:focus {
  border: none;
}

.zoom-button:visited {
  border: none;
}

.zoom-box {
  width: 30px;
  height: 20px;
  margin-right: -35px;
  z-index: 999;
  background-color: white;
  color: black;
  margin-left: 15px;
}

.toolbar-color-palette {
  display: flex;
}
</style>
