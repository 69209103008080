<template>
  <div class="containers">
    <LeftImage />
    <div class="case-container">
      <MenuIcon />
      <div class="fs30 bold case-title">
        List Case
        <span class="breadcrumbs"
          >Assignment <img src="../assets/arrow.png" /><span>Disease</span>
          <img src="../assets/arrow.png" /><span class="breadcrumbs-2"
            >Case</span
          >
        </span>
      </div>
      <div v-if="caseList.length == 0 && !isDoneFetching" class="spinner">
        <Spinner />
      </div>
      <div v-if="caseList.length == 0 && isDoneFetching" class="spinner">
        Tidak ada case
      </div>

      <div v-for="(list, index) in caseList" :key="list.name">
        <router-link
          :to="`/assignment/${assignmentId}/${diseaseId}/${list.id}`"
          exact
        >
          <b-button type="is-primary" outlined class="case-box">
            <div class="case-item">
              <div class="fs18 case-num">{{ index + 1 }}</div>
              <div class="fs18 bold case-name">{{ list.name }}</div>
              <div class="fs12 case-participant">
                <div class="slide-count">{{ list.slide_count }} Slides</div>
              </div>
            </div>
          </b-button></router-link
        >
      </div>
    </div>
    <div class="footers">Powered by Neurabot</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../components/Spinner";
import MenuIcon from "../components/MenuIcon";
import LeftImage from "../components/LeftImage";

export default {
  data() {
    return {
      assignmentId: this.$route.params.assignment_id,
      diseaseId: this.$route.params.disease_id,
    };
  },
  components: {
    Spinner,
    LeftImage,
    MenuIcon,
  },
  computed: mapState({
    caseList: (state) => state.cases.caseList,
    isDoneFetching: (state) => state.cases.isDoneFetching,
    assignmentList: (state) => state.assignmentList,
  }),
  created() {
    let params = {
      diseaseId: this.diseaseId,
      assignmentId: this.assignmentId,
    };
    this.$store.dispatch("getCase", params);
  },
};
</script>

<style scoped>
.footers {
  width: 100%;
  height: 45px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: flex-start;
  bottom: 0;
  position: fixed;
  background-color: #3a4659;
  color: white;
  padding-left: 20px;
  z-index: 1000;
}

.breadcrumbs {
  font-size: 12px;
  color: #aaaaaa;
  display: flex;
  align-items: center;
}

.breadcrumbs-2 {
  color: #7957d5;
}

.containers {
  width: 100%;
  height: 100%;
  display: flex;
}

.case-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 320px;
  padding-bottom: 100px;
}

.case-title {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 780px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-count {
  width: 92px;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  background-color: #f46a6a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-box {
  width: 780px;
  height: 58px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  font-weight: 300;
}

.case-name {
  width: 550px;
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
  font-weight: 500;
}

.case-num {
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 13px;
  color: #7957d5;
  font-weight: 500;
}

.case-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.spinner {
  width: 780px;
  display: flex;
  justify-content: center;
}
</style>
