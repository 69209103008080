<template>
  <div class="container">
    <div class="fs24 bold">Enhance Image</div>
    <div class="fs14">Brightness & Contrast</div>
    <div class="slider-item">
      <div><b-icon icon="weather-sunny"> </b-icon></div>
      <b-field class="slider">
        <b-slider
          rounded
          v-model="brightnessVal"
          size="is-small"
          :min="-1"
          :max="1"
          :step="0.01"
          lazy
        ></b-slider>
      </b-field>
      <div class="fs14 bright-val">{{ brightnessVal }}</div>
    </div>
    <div class="slider-item">
      <div><b-icon icon="circle-half-full"> </b-icon></div>
      <b-field class="slider">
        <b-slider
          rounded
          v-model="contrastVal"
          size="is-small"
          :min="-1"
          :max="1"
          :step="0.01"
          lazy
        ></b-slider>
      </b-field>
      <div class="fs14 contrast-val">{{ contrastVal }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["enhanceState"],

  data() {
    return {
      brightnessVal: 0,
      contrastVal: 0,
    };
  },
  watch: {
    brightnessVal: function() {
      this.enhanceState.brightness = this.brightnessVal;
    },
    contrastVal: function() {
      this.enhanceState.contrast = this.contrastVal;
    },
  },
};
</script>

<style scoped>
.container {
  width: 85%;
  margin: 20px 0 0 20px;
}

.slider-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider {
  width: 50%;
  margin-top: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.bright-val {
  min-width: 40px;
}

.contrast-val {
  min-width: 40px;
}
</style>
