import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = { slideList: [] };

const actions = {
  getSlide: ({ commit }, caseId) => {
    commit("resetSlide");
    axios({
      method: "get",
      url: BASE_URL + `api/list-slides/` + caseId + "/",
    })
      .then((resp) => commit("setSlide", resp.data.slides))
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  resetSlide: (state) => {
    state.slideList = [];
  },
  setSlide: (state, resp) => {
    state.slideList = resp;
  },
};

export default {
  state,
  actions,
  mutations,
};
