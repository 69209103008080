<template>
  <div class="trash-container" :style="style">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      :fill="iconColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 3V1.5H12.75V3H16.5V4.5H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V4.5H1.5V3H5.25ZM4.5 4.5V15H13.5V4.5H4.5ZM6.75 6.75H8.25V12.75H6.75V6.75ZM9.75 6.75H11.25V12.75H9.75V6.75Z"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    style() {
      return "background-color: " + this.background;
    },
  },
};
</script>

<style>
.trash-container {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: 0 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
