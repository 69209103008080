<template>
  <div :v-if="userAnswerList" class="container">
    <div class="fs24 bold">Answer</div>
    <div v-for="item in userAnswerList" :key="item.id" class="question-item">
      <div class="fs14 question">{{ item.question }}</div>
      <div class="box-ans-con">
        <!-- Masing-masing tampilan jawaban terdiri dari 2 box, atas dan bawah -->
        <!-- Jika soalnya merupakan soal pilihan ganda, box1 untuk pilihan pertama box2 untuk pilihan kedua -->
        <!-- Jika soalnya merupakan isian, box1 menampilkan jawaban user, box2 menampilkan jawaban yang benar  -->
        <!-- Jika soalnya isian dan jawaban user benar, maka box2 dikosongkan-->

        <!-- BOX 1 -->
        <div class="box-ans">
          <!-- div dibawah ini muncul jika soalnya pilihan ganda -->
          <div
            class="box-ans-text"
            :style="boxStyle1(item)"
            v-if="item.choice_answer && item.choice_answer.length"
          >
            {{
              item.choice_answer &&
                item.choice_answer[0] &&
                item.choice_answer[0].answer
            }}
            <div
              class="your-ans"
              :style="ansStyle1(item)"
              v-if="item.choice_answer"
            >
              Your Answer
            </div>
          </div>
          <!-- div dibawah ini muncul jika soalnya isian -->
          <div class="box-ans-text" :style="boxStyle1(item)" v-else>
            {{
              item.answer_user &&
                item.answer_user[0] &&
                item.answer_user[item.answer_user.length - 1].answer_text
            }}
            <div
              class="your-ans"
              :style="ansStyle1(item)"
              v-if="
                item.answer_user[0] &&
                  item.answer_user[item.answer_user.length - 1].answer_text &&
                  item.answer_user[item.answer_user.length - 1].answer_text !==
                    item.is_answer_text
              "
            >
              Your Answer
            </div>
          </div>
        </div>
        <!-- BOX 2 -->
        <div class="box-ans">
          <!-- div dibawah ini muncul jika soalnya pilihan ganda -->
          <div
            class="box-ans-text"
            :style="boxStyle2(item)"
            v-if="item.choice_answer && item.choice_answer.length"
          >
            {{
              item.choice_answer &&
                item.choice_answer[1] &&
                item.choice_answer[1].answer
            }}
            <div
              class="your-ans"
              :style="ansStyle2(item)"
              v-if="item.choice_answer"
            >
              Your Answer
            </div>
          </div>
          <!-- div dibawah ini muncul jika soalnya isian -->
          <div
            class="box-ans-text-true"
            v-else-if="
              item.answer_user[0] &&
                item.answer_user[item.answer_user.length - 1].answer_text &&
                item.answer_user[item.answer_user.length - 1].answer_text ==
                  item.is_answer_text
            "
          >
            {{ item.is_answer_text }}
          </div>
        </div>
      </div>
      <img src="../assets/line-hor.png" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      caseId: this.$route.params.case_id,
      userAnswerList: [],
    };
  },
  computed: {
    ...mapState({
      userAnswerLists: (state) => state.answer.userAnswerList,
    }),
  },
  methods: {
    boxStyle1: function(item) {
      // Untuk styling box jawaban (box 1)
      if (
        // Jika jawaban user benar
        item.answer_user[0] &&
        item.answer_user[item.answer_user.length - 1].answer &&
        item.answer_user[item.answer_user.length - 1].answer.id ===
          item.is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang pertama
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[0].id
        ) {
          return "background-color: #4caf50 ";
        } else {
          return "";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang pertama
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[0].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "background-color: #D4C4FF";
        }
      }
    },
    boxStyle2: function(item) {
      // Untuk styling box jawaban (box 2)
      if (
        // Jika jawaban user benar
        item.answer_user[0] &&
        item.answer_user[item.answer_user.length - 1].answer &&
        item.answer_user[item.answer_user.length - 1].answer.id ===
          item.is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang kedua
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[1].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang kedua
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[1].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "background-color: #D4C4FF";
        }
      }
    },
    ansStyle1: function(item) {
      if (
        // Jika jawaban user benar
        item.answer_user[0] &&
        item.answer_user[item.answer_user.length - 1].answer &&
        item.answer_user[item.answer_user.length - 1].answer.id ===
          item.is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang pertama
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[0].id
        ) {
          return "background-color : green";
        } else {
          return "display : none";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang pertama
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[0].id
        ) {
          return "display : none";
        } else {
          return "";
        }
      }
    },
    ansStyle2: function(item) {
      if (
        // Jika jawaban user benar
        item.answer_user[0] &&
        item.answer_user[item.answer_user.length - 1].answer &&
        item.answer_user[item.answer_user.length - 1].answer.id ===
          item.is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang kedua
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[1].id
        ) {
          return "background-color : green";
        } else {
          return "display : none";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang kedua
          item.choice_answer &&
          item.is_answer &&
          item.is_answer.id == item.choice_answer[1].id
        ) {
          return "display : none";
        } else {
          return "";
        }
      }
    },
  },
  created() {
    this.$store.dispatch("getUserAnswer", this.$route.params.case_id);
  },
  watch: {
    userAnswerLists: function() {
      this.userAnswerList = this.userAnswerLists.question.filter(
        (item) =>
          item.answer_user[item.answer_user.length - 1].answer !== null ||
          item.answer_user[item.answer_user.length - 1].answer_text !== null
      );
    },
  },
};
</script>

<style scoped>
.modals {
  width: 480px;
  height: 285px;
  background: white;
  padding: 30px;
}
.container {
  width: 85%;
  margin: 20px 10px 0 20px;
}
.question {
  margin: 20px 0 20px 0;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.modal-ans {
  height: 478px;
  width: 480px;
  background-color: white;
}

.button-ans-left-1 {
  cursor: pointer;
}
.box-ans-text {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-ans-text-true {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #4caf50;
  justify-content: space-between;
}

.modal-ans-body {
  margin-left: 35px;
}

.modal-ans-header {
  width: 100%;
  height: 58px;
  background-color: #7957d5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
}

.box-ans-con {
  width: 100%;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.box-ans-2 {
  width: 100%;
  height: 150px;
  margin-top: 10px;
}

.box-ans {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.modal-title-1 {
  margin-top: 26px;
  font-size: 11px;
  font-weight: 700;
}

.modal-title-2 {
  font-size: 13px;
  margin-top: 5px;
}

.modal-title-3 {
  font-size: 12px;
  color: #00c372;
  margin-top: 16px;
}

.modal-title-4 {
  font-size: 12px;
  color: #ec1c24;
  margin-top: 16px;
}

.button-ans {
  margin-top: 21px;
  width: 409px;

  display: flex;
  justify-content: space-between;
}

.button-ans-left {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.button-ans-left-2 {
  font-size: 12px;
  height: 30px;
  width: 108px;
  margin-left: 20px;
}

.button-ans-left-done {
  font-size: 12px;
  height: 30px;
  width: 108px;
  margin-left: 200px;
}

.button-ans-right {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.your-ans {
  height: 20px;
  border-radius: 10px;
  font-size: 10px;
  width: 80px;
  background-color: #f46a6a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-ans-right-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 21px;
  background-color: rgba(121, 87, 213, 0.23);
  border-radius: 2px;
  margin-right: 4px;
}

.submit-buttons {
  margin-bottom: 30px;
}
</style>
