<template>
  <div class="eye-active-container" :style="style">
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      :fill="iconColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5303 18H2.07491C1.60487 18 1.15408 17.8134 0.821707 17.4812C0.489336 17.149 0.302612 16.6984 0.302612 16.2287L0.302612 1.77134C0.302612 1.30155 0.489336 0.851005 0.821707 0.518814C1.15408 0.186623 1.60487 0 2.07491 0L20.5303 0C21.0004 0 21.4511 0.186623 21.7835 0.518814C22.1159 0.851005 22.3026 1.30155 22.3026 1.77134V16.2287C22.3026 16.6984 22.1159 17.149 21.7835 17.4812C21.4511 17.8134 21.0004 18 20.5303 18ZM2.07491 1.38116C1.97137 1.38116 1.87208 1.42227 1.79886 1.49544C1.72565 1.56862 1.68452 1.66786 1.68452 1.77134V16.2287C1.68452 16.2799 1.69462 16.3306 1.71424 16.378C1.73386 16.4253 1.76261 16.4683 1.79886 16.5046C1.83512 16.5408 1.87815 16.5695 1.92552 16.5891C1.97288 16.6087 2.02364 16.6188 2.07491 16.6188H20.5303C20.6332 16.6179 20.7317 16.5764 20.8041 16.5033C20.8766 16.4303 20.9172 16.3315 20.9172 16.2287V1.77134C20.9172 1.66845 20.8766 1.56973 20.8041 1.49666C20.7317 1.42358 20.6332 1.38207 20.5303 1.38116H2.07491Z"
        :fill="iconColor"
      />
      <path
        d="M4.47596 15.0064H18.1258C18.2351 15.0095 18.3431 14.9819 18.4374 14.9266C18.5318 14.8714 18.6088 14.7908 18.6595 14.694C18.7103 14.5972 18.7328 14.4881 18.7246 14.3791C18.7163 14.2701 18.6776 14.1656 18.6129 14.0776L16.73 11.5224C16.6809 11.4544 16.6177 11.3978 16.5447 11.3565C16.4716 11.3151 16.3906 11.29 16.307 11.2828C16.2234 11.2756 16.1392 11.2865 16.0602 11.3147C15.9811 11.3429 15.9091 11.3879 15.8491 11.4465L13.9144 13.2868L9.4232 7.19247C9.36728 7.11633 9.29371 7.0549 9.20881 7.01344C9.1239 6.97199 9.03019 6.95175 8.93573 6.95447C8.84128 6.95718 8.74889 6.98277 8.66651 7.02904C8.58413 7.07531 8.51421 7.14087 8.46277 7.22009L3.97157 14.1052C3.91364 14.1943 3.88118 14.2976 3.87767 14.4038C3.87416 14.51 3.89974 14.6152 3.95166 14.7079C4.00357 14.8007 4.07984 14.8775 4.17224 14.9301C4.26464 14.9827 4.36966 15.0091 4.47596 15.0064Z"
        :fill="iconColor"
      />
      <path
        d="M15.5486 7.26836C16.68 7.26836 17.5972 6.35163 17.5972 5.22079C17.5972 4.08995 16.68 3.17322 15.5486 3.17322C14.4171 3.17322 13.4999 4.08995 13.4999 5.22079C13.4999 6.35163 14.4171 7.26836 15.5486 7.26836Z"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    style() {
      return "background-color: " + this.background;
    },
  },
};
</script>

<style>
.eye-active-container {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: 0 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
