import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = { annotationList: [] };

const actions = {
  createAnnotation: ({ commit }, param) => {
    axios({
      method: "POST",
      url: BASE_URL + `api/create-annotation/`,
      data: {
        slide_id: param.slideId,
        annotation: `{"type":"circle","center":[${param.coordinateX},${param.coordinateY}],"color":[${param.color}]}`,
      },
    }).then(() => console.log(commit));
  },
  getAnnotation: ({ commit }, slideId) => {
    axios({
      method: "get",
      url: BASE_URL + `api/list-annotation/` + slideId + "/",
    })
      .then((resp) => commit("setAnnotation", { resp, slideId }))
      .catch((err) => {
        console.log(err);
      });
  },
  deleteAnnotation: ({ commit }, param) => {
    axios({
      method: "delete",
      url: BASE_URL + `api/delete-multiple-annotation/`,
      data: {
        anno_id: param,
      },
    })
      .then(() => commit())
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setAnnotation: (state, { resp, slideId }) => {
    state.annotationList = state.annotationList.concat({
      slideId: slideId,
      annot: resp.data.annotations.map((item) => ({
        id: item.id,
        annot: JSON.parse(item.annotation),
      })),
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
