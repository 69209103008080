var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login page"},[_c('div',{staticClass:"containers"},[_c('div',{staticClass:"left-sidebar",attrs:{"id":"left-sidebar"}},[_c('SlideList',{attrs:{"state":_vm.state}})],1),_c('div',{staticClass:"image-container",attrs:{"id":"image-container"}},[_c('Toolbar',{attrs:{"state":_vm.state,"zoomVal":_vm.state.zoomVal,"showRightSibebars":_vm.showRightSidebars},on:{"showRightBar":_vm.showRightBar,"changeZoom":_vm.changeZoom}}),_vm._l((_vm.slideList),function(list){return _c('div',{key:list.id},[(_vm.loadImage(list.id))?_c('ImageView',{directives:[{name:"show",rawName:"v-show",value:(list.id == _vm.state.selectedSlide),expression:"list.id == state.selectedSlide"}],attrs:{"slideId":list.id,"imageUrl":list.slide,"thumbnailUrl":list.thumbnail,"zoomSize":_vm.zoomSize,"state":_vm.state,"annotColor":_vm.state.annotColor,"showRightSidebar":_vm.state.showRightSidebar,"clickDelete":_vm.state.clickDelete,"brightness":_vm.enhanceState.brightness,"contrast":_vm.enhanceState.contrast}}):_vm._e()],1)})],2),_c('div',{staticClass:"right-sidebar",attrs:{"id":"right-sidebar"}},[_c('div',{staticClass:"closeButton",on:{"click":_vm.closeRightSidebar}},[_c('img',{staticClass:"toolbar-icon",attrs:{"src":require("../assets/arrow-box.png")}})]),_c('EnhanceImage',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.showEnhance),expression:"state.showEnhance"}],attrs:{"enhanceState":_vm.enhanceState}}),_c('QuestionList',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.state.showEnhance &&
            _vm.assignmentStatus !== 'completed' &&
            _vm.userAnswerList.question &&
            _vm.userAnswerList.question[0] &&
            _vm.userAnswerList.question[0].answer_user.length === 0 &&
            _vm.state.isAssignmentSubmited === false
        ),expression:"\n          !state.showEnhance &&\n            assignmentStatus !== 'completed' &&\n            userAnswerList.question &&\n            userAnswerList.question[0] &&\n            userAnswerList.question[0].answer_user.length === 0 &&\n            state.isAssignmentSubmited === false\n        "}],attrs:{"state":_vm.state}}),(
          !_vm.state.showEnhance &&
            (_vm.assignmentStatus === 'completed' ||
              (_vm.userAnswerList.question &&
                _vm.userAnswerList.question[0] &&
                _vm.userAnswerList.question[0].answer_user.length >= 1) ||
              _vm.state.isAssignmentSubmited === true)
        )?_c('AnswerList'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }