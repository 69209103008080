<template>
  <div class="containers">
    <LeftImage />
    <div class="disease-container">
      <MenuIcon />
      <div class="fs30 bold disease-title">
        List Disease
        <span class="breadcrumbs"
          >Assignment <img src="../assets/arrow.png" /><span
            class="breadcrumbs-2"
            >Disease</span
          ></span
        >
      </div>
      <div v-if="diseaseList.length == 0" class="spinner"><Spinner /></div>
      <div v-for="(list, index) in diseaseList" :key="list.id">
        <router-link :to="`/assignment/${assignmentId}/${list.id}`" exact>
          <b-button type="is-primary" outlined class="disease-box">
            <div class="disease-item">
              <div class="fs18 disease-num">{{ index + 1 }}</div>
              <div class="fs18 bold disease-name">{{ list.name }}</div>
              <div class="fs12 disease-participant">
                <div class="case-count-box">{{ list.case_count }} Case</div>
              </div>
            </div>
          </b-button></router-link
        >
      </div>
    </div>
    <div class="footers">Powered by Neurabot</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../components/Spinner";
import MenuIcon from "../components/MenuIcon";
import LeftImage from "../components/LeftImage";

export default {
  components: {
    Spinner,
    LeftImage,
    MenuIcon,
  },
  data() {
    return {
      assignmentId: this.$route.params.assignment_id,
    };
  },
  computed: mapState({
    diseaseList: (state) => state.disease.diseaseList,
  }),
  created() {
    let params = {
      assignmentId: this.assignmentId,
    };
    this.$store.dispatch("getDisease", params);
  },
};
</script>

<style scoped>
.breadcrumbs {
  font-size: 12px;
  color: #aaaaaa;
  display: flex;
  align-items: center;
}

.breadcrumbs-2 {
  color: #7957d5;
}

.footers {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 0;
  position: fixed;
  background-color: #3a4659;
  color: white;
  font-size: 12px;
  padding-left: 20px;
  z-index: 1000;
}

.containers {
  width: 100%;
  height: 100%;
}

.disease-container {
  width: 100%;
  height: 100%;
  padding-left: 320px;
  padding-bottom: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: scroll;
}

.disease-title {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 780px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-count-box {
  width: 92px;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  background-color: #f46a6a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disease-box {
  width: 780px;
  height: 58px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  font-weight: 300;
}

.disease-name {
  width: 550px;
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
  font-weight: 500;
}

.disease-num {
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 13px;
  color: #7957d5;
  font-weight: 500;
}

.disease-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.spinner {
  width: 780px;
  display: flex;
  justify-content: center;
}
</style>
