<template>
  <div class="login-page">
    <div class="containers">
      <div class="login-box">
        <div class="titles bold">Login</div>
        <form @submit.prevent="login">
          <section>
            <b-field label="Username" custom-class="is-small" class="fields">
              <b-input
                type="text"
                required
                v-model="username"
                maxlength="30"
                expanded
              >
              </b-input>
            </b-field>
            <b-field label="Password" custom-class="is-small" class="fields">
              <b-input
                type="password"
                required
                v-model="password"
                password-reveal
                expanded
              >
              </b-input>
            </b-field>
          </section>
          <div class="error-text" v-show="authStatus == `error`">
            Invalid username or password
          </div>
          <div class="login-button-container fs12">
            <b-button
              native-type="submit"
              type="is-primary"
              class="login-button"
              :loading="isLoading"
              >Masuk</b-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  computed: {
    authStatus: function() {
      return this.$store.getters.authStatus;
    },
  },
  methods: {
    login: function() {
      this.isLoading = true;
      let username = this.username;
      let password = this.password;
      this.$store
        .dispatch("login", { username, password })
        .then(() => this.$router.push("/assignment"))
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped>
.error-text {
  font-size: 12px;
  color: red;
}

.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(95, 58, 196, 0.6), rgba(95, 58, 196, 0.6)),
    url("../assets/background.png");
  background-size: cover;
}
.login-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.containers {
  width: 425px;
  height: 432px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}

.titles {
  font-size: 30px;
  margin: 10px 0px;
}
section {
  width: 100%;
}

.fields {
  margin: 10px 0px;
}

.login-button {
  margin-right: 30px;
}

.login-button-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
</style>
