<template>
  <div class="question-container" :style="style">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      :fill="iconColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 2.4H10.4C10.4 1.76348 10.1471 1.15303 9.69706 0.702944C9.24697 0.252856 8.63652 0 8 0C7.36348 0 6.75303 0.252856 6.30294 0.702944C5.85286 1.15303 5.6 1.76348 5.6 2.4H1.6C1.17565 2.4 0.768687 2.56857 0.468629 2.86863C0.168571 3.16869 0 3.57565 0 4L0 14.4C0 14.8243 0.168571 15.2313 0.468629 15.5314C0.768687 15.8314 1.17565 16 1.6 16H14.4C14.8243 16 15.2313 15.8314 15.5314 15.5314C15.8314 15.2313 16 14.8243 16 14.4V4C16 3.57565 15.8314 3.16869 15.5314 2.86863C15.2313 2.56857 14.8243 2.4 14.4 2.4ZM8 1.6C8.15823 1.6 8.3129 1.64692 8.44446 1.73482C8.57602 1.82273 8.67855 1.94767 8.7391 2.09385C8.79965 2.24003 8.8155 2.40089 8.78463 2.55607C8.75376 2.71126 8.67757 2.8538 8.56569 2.96569C8.4538 3.07757 8.31126 3.15376 8.15607 3.18463C8.00089 3.2155 7.84003 3.19965 7.69385 3.1391C7.54767 3.07855 7.42273 2.97602 7.33482 2.84446C7.24692 2.7129 7.2 2.55822 7.2 2.4C7.2 2.18783 7.28429 1.98434 7.43432 1.83431C7.58434 1.68429 7.78783 1.6 8 1.6ZM2.4 5.6H5.6V7.2H2.4V5.6ZM2.4 8.8H7.2V10.4H2.4V8.8ZM8.8 13.6H2.4V12H8.8V13.6ZM12 13.6H10.4V12H12V13.6ZM12 10.36V11.2H10.4V8.8H11.6C11.8373 8.8 12.0693 8.72962 12.2667 8.59776C12.464 8.46591 12.6178 8.27849 12.7087 8.05922C12.7995 7.83995 12.8232 7.59867 12.7769 7.36589C12.7306 7.13311 12.6164 6.91929 12.4485 6.75147C12.2807 6.58365 12.0669 6.46936 11.8341 6.42306C11.6013 6.37676 11.3601 6.40052 11.1408 6.49134C10.9215 6.58217 10.7341 6.73598 10.6022 6.93332C10.4704 7.13065 10.4 7.36266 10.4 7.6H8.8C8.79784 7.06282 8.95024 6.53635 9.23904 6.0834C9.52784 5.63045 9.94084 5.27014 10.4288 5.04544C10.9167 4.82074 11.459 4.74115 11.9909 4.81615C12.5228 4.89115 13.0219 5.11758 13.4287 5.46843C13.8355 5.81928 14.1328 6.27974 14.2851 6.79488C14.4374 7.31002 14.4383 7.85811 14.2877 8.37375C14.1371 8.88939 13.8413 9.35082 13.4357 9.703C13.0301 10.0552 12.5317 10.2833 12 10.36Z"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    style() {
      return "background-color: " + this.background;
    },
  },
};
</script>

<style>
.question-container {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
