<template>
  <div :v-if="questionList" class="container">
    <div class="fs24 bold">Question</div>
    <form @submit.prevent="handleClickSubmit">
      <div
        v-for="(item, index) in questionList"
        :key="item.id"
        class="question-item"
      >
        <div class="fs14 question">{{ item.question }}</div>
        <div v-if="item.choice_answer.length === 0">
          <b-input
            required
            v-model="essayAnswer"
            @change.native="handleSelectAnswer(index, null, null)"
            maxlength="200"
            type="textarea"
          ></b-input>
        </div>
        <div
          v-else
          v-for="answerItem in item.choice_answer"
          :key="answerItem.answer"
          class="field"
        >
          <b-radio
            v-model="answerId[index]"
            :name="'question' + index"
            :native-value="answerItem.answer"
            required
            @click.native="
              handleSelectAnswer(index, answerItem.id, answerItem.answer)
            "
            class="fs14"
          >
            {{ answerItem.answer }}
          </b-radio>
        </div>
        <img src="../assets/line-hor.png" />
      </div>
      <div v-if="showSubmit">
        <b-button
          native-type="submit"
          type="is-primary"
          outlined
          expanded
          class="submit-buttons"
          >Submit</b-button
        >
      </div>
    </form>
    <!-- Modal yang muncul setelah submit jawaban -->
    <b-modal v-model="showModal" :width="480" :on-cancel="handleClickDone">
      <div class="modal-ans" v-if="this.showAnswer">
        <div class="modal-ans-header">Quiz Answer</div>
        <div class="modal-ans-body">
          <div class="modal-title-1">
            Slide Case #{{ this.$route.params.case_id }}
          </div>
          <div>
            <div class="modal-title-2">
              {{ answerAll && answerAll[activeAnswer].question }}
            </div>
            <div>
              <!-- Code v-if yang panjang dibawah ini untuk mengecek apakah jawaban user benar atau salah-->
              <div
                v-if="
                  answerAll &&
                    answerAll[activeAnswer] &&
                    answerAll[activeAnswer].answer_user[0] &&
                    answerAll[activeAnswer].answer_user[
                      answerAll[activeAnswer].answer_user.length - 1
                    ].answer &&
                    answerAll[activeAnswer].answer_user[
                      answerAll[activeAnswer].answer_user.length - 1
                    ].answer.id === answerAll[activeAnswer].is_answer.id
                "
                class="modal-title-3"
              >
                Your answer is correct
              </div>
              <div v-else class="modal-title-4">Your answer is incorrect</div>
            </div>
            <div class="box-ans-con">
              <!-- Masing-masing tampilan jawaban terdiri dari 2 box, atas dan bawah -->
              <!-- Jika soalnya merupakan soal pilihan ganda, box1 untuk pilihan pertama box2 untuk pilihan kedua -->
              <!-- Jika soalnya merupakan isian, box1 menampilkan jawaban user, box2 menampilkan jawaban yang benar  -->
              <!-- Jika soalnya isian dan jawaban user benar, maka box2 dikosongkan-->

              <!-- BOX 1 -->
              <div class="box-ans">
                <!-- div dibawah ini muncul jika soalnya pilihan ganda -->
                <div
                  class="box-ans-text"
                  :style="boxStyle1"
                  v-if="
                    answerAll &&
                      answerAll[activeAnswer] &&
                      answerAll[activeAnswer].choice_answer &&
                      answerAll[activeAnswer].choice_answer.length
                  "
                >
                  {{
                    answerAll &&
                      answerAll[activeAnswer] &&
                      answerAll[activeAnswer].choice_answer &&
                      answerAll[activeAnswer].choice_answer[0] &&
                      answerAll[activeAnswer].choice_answer[0].answer
                  }}
                  <div
                    class="your-ans"
                    :style="ansStyle1"
                    v-if="
                      answerAll &&
                        answerAll[activeAnswer] &&
                        answerAll[activeAnswer].choice_answer &&
                        answerAll[activeAnswer].answer_user[0] &&
                        answerAll[activeAnswer].answer_user[
                          answerAll[activeAnswer].answer_user.length - 1
                        ].answer.id ===
                          answerAll[activeAnswer].choice_answer[0].id
                    "
                  >
                    Your Answer
                  </div>
                </div>
                <!-- div dibawah ini muncul jika soalnya isian -->
                <div class="box-ans-text" :style="boxStyle1" v-else>
                  {{
                    answerAll &&
                      answerAll[activeAnswer].answer_user[0] &&
                      answerAll[activeAnswer].answer_user[
                        answerAll[activeAnswer].answer_user.length - 1
                      ].answer_text
                  }}
                  <div
                    class="your-ans"
                    :style="ansStyle1"
                    v-if="answerAll && !answerAll[activeAnswer].is_true"
                  >
                    Your Answer
                  </div>
                </div>
              </div>

              <!-- BOX 2 -->
              <div class="box-ans">
                <!-- div dibawah ini muncul jika soalnya pilihan ganda -->
                <div
                  class="box-ans-text"
                  :style="boxStyle2"
                  v-if="
                    answerAll &&
                      answerAll[activeAnswer] &&
                      answerAll[activeAnswer].choice_answer &&
                      answerAll[activeAnswer].choice_answer.length
                  "
                >
                  {{
                    answerAll &&
                      answerAll[activeAnswer] &&
                      answerAll[activeAnswer].choice_answer &&
                      answerAll[activeAnswer].choice_answer[1] &&
                      answerAll[activeAnswer].choice_answer[1].answer
                  }}
                  <div
                    class="your-ans"
                    :style="ansStyle2"
                    v-if="
                      answerAll &&
                        answerAll[activeAnswer] &&
                        answerAll[activeAnswer].choice_answer &&
                        answerAll[activeAnswer].answer_user[0] &&
                        answerAll[activeAnswer].answer_user[
                          answerAll[activeAnswer].answer_user.length - 1
                        ].answer.id ===
                          answerAll[activeAnswer].choice_answer[1].id
                    "
                  >
                    Your Answer
                  </div>
                </div>
                <!-- div dibawah ini muncul jika soalnya isian -->
                <!-- Code v-else-if yang panjang dibawah ini untuk mengecek apakah jawaban user benar atau salah-->
                <div
                  class="box-ans-text7"
                  v-else-if="
                    answerAll &&
                      answerAll[activeAnswer].answer_user[0] &&
                      answerAll &&
                      answerAll[activeAnswer].answer_user[
                        answerAll[activeAnswer].answer_user.length - 1
                      ].answer_text &&
                      answerAll &&
                      answerAll[activeAnswer].answer_user[
                        answerAll[activeAnswer].answer_user.length - 1
                      ].answer_text !== answerAll &&
                      answerAll[activeAnswer].is_answer_text
                  "
                >
                  {{
                    answerAll &&
                      answerAll[activeAnswer] &&
                      answerAll[activeAnswer].is_answer_text
                  }}
                </div>
              </div>
            </div>
            <div class="box-ans-2"></div>
          </div>
          <div class="button-ans">
            <div class="button-ans-left">
              <div
                class="button-ans-left-1"
                @click="activeAnswer = activeAnswer - 1"
                v-show="activeAnswer > 0"
              >
                Previous
              </div>
              <b-button
                class="button-ans-left-2"
                type="is-primary"
                size="is-small"
                @click="activeAnswer = activeAnswer + 1"
                v-show="activeAnswer < (answerAll && answerAll.length) - 1"
                >Next</b-button
              >
              <b-button
                class="button-ans-left-done"
                type="is-primary"
                size="is-small"
                @click="handleClickDone"
                v-show="activeAnswer == (answerAll && answerAll.length) - 1"
                >Done</b-button
              >
            </div>
            <div class="button-ans-right">
              <div class="button-ans-right-box">
                {{ activeAnswer + 1 }}
              </div>
              of {{ answerAll && answerAll.length }}
            </div>
          </div>
        </div>
      </div>
      <!-- Modal untuk Submit -->
      <div class="modals" v-else>
        <div>
          <div class="fs24 bold">Submit answer</div>
          <div class="fs16">
            Please check your answer before submiting. Are you sure want to
            submit you answer now?
            <div class="buttons">
              <b-button type="is-light" @click="showModal = false"
                >Cancel</b-button
              >
              <b-button
                :loading="isLoadingButton"
                type="is-primary"
                @click="handleSubmitAnswer"
                >Submit Answer</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["state"],
  components: {},
  data() {
    return {
      caseId: this.$route.params.case_id,
      answerId: [],
      answerIds: [null],
      questionId: [],
      essayAnswer: "",
      showModal: false,
      showSubmit: false,
      isLoadingButton: false,
      showAnswer: false,
      activeAnswer: 0,
      essayIndex: null,
      answerAll: [],
    };
  },
  computed: {
    ...mapState({
      questionList: (state) => state.question.questionList,
      answerAlls: (state) =>
        state.question.answer && state.question.answer.question,
    }),
    boxStyle1() {
      // Untuk styling box jawaban (box 1)
      if (
        // Jika jawaban user benar
        this.answerAll &&
        this.answerAll[this.activeAnswer] &&
        this.answerAll[this.activeAnswer].answer_user[0] &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer.id === this.answerAll[this.activeAnswer].is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang pertama
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[0].id
        ) {
          return "background-color: #4caf50 ";
        } else {
          return "";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang pertama
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[0].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "background-color: #D4C4FF";
        }
      }
    },
    boxStyle2() {
      // Untuk styling box jawaban (box 2)
      if (
        // Jika jawaban user benar
        this.answerAll &&
        this.answerAll[this.activeAnswer] &&
        this.answerAll[this.activeAnswer].answer_user[0] &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer.id === this.answerAll[this.activeAnswer].is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang kedua
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[1].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang kedua
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[1].id
        ) {
          return "background-color: #4caf50";
        } else {
          return "background-color: #D4C4FF";
        }
      }
    },
    ansStyle1() {
      if (
        // Jika jawaban user benar
        this.answerAll &&
        this.answerAll[this.activeAnswer] &&
        this.answerAll[this.activeAnswer].answer_user[0] &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer.id === this.answerAll[this.activeAnswer].is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang pertama
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[0].id
        ) {
          return "background-color: green";
        } else {
          return "background-color: green";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang pertama
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[0].id
        ) {
          return "background-color: green";
        } else {
          return "";
        }
      }
    },
    ansStyle2() {
      if (
        // Jika jawaban user benar
        this.answerAll &&
        this.answerAll[this.activeAnswer] &&
        this.answerAll[this.activeAnswer].answer_user[0] &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer &&
        this.answerAll[this.activeAnswer].answer_user[
          this.answerAll[this.activeAnswer].answer_user.length - 1
        ].answer.id === this.answerAll[this.activeAnswer].is_answer.id
      ) {
        if (
          // Jika jawaban yang benar adalah option yang kedua
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[1].id
        ) {
          return "background-color: green";
        } else {
          return "background-color: green";
        }
      } else {
        // Jika jawaban user salah
        if (
          // Jika jawaban yang benar adalah option yang kedua
          this.answerAll &&
          this.answerAll[this.activeAnswer].choice_answer &&
          this.answerAll[this.activeAnswer].is_answer &&
          this.answerAll[this.activeAnswer].is_answer.id ==
            this.answerAll[this.activeAnswer].choice_answer[1].id
        ) {
          return "background-color: green";
        } else {
          return "";
        }
      }
    },
  },
  mounted() {
    this.getFirstQuestion(this.$route.params.case_id);
  },
  methods: {
    handleSelectAnswer: function(index, id, answer) {
      this.answerId[index + 1] = null;
      if (index === 0) {
        let param = {
          id: this.$route.params.case_id,
          type: answer,
        };
        this.answerIds.splice(1, this.answerIds.length - 1);
        this.answerIds[0] = id;
        this.essayAnswer = "";
        this.getNextQuestion(param);
        this.showSubmit = true;
      } else if (id == null) {
        this.answerIds[index] = this.essayAnswer;
      } else {
        this.answerIds[index] = id;
      }
    },
    handleClickSubmit: function() {
      this.showModal = true;
      this.showAnswer = false;
    },
    handleClickDone: function() {
      this.showModal = false;
      this.state.isAssignmentSubmited = true;
      this.$router.push(
        `/assignment/${this.$route.params.assignment_id}/${this.$route.params.disease_id}/${this.$route.params.case_id}`
      );
    },
    getFirstQuestion: function(id) {
      this.$store.dispatch("getFirstQuestion", id);
    },
    getNextQuestion: function(id) {
      this.$store.dispatch("getNextQuestion", id);
    },
    handleSubmitAnswer: function() {
      this.isLoadingButton = true;
      this.$store.dispatch("submitAnswer", {
        case_id: this.$route.params.case_id,
        question_id: this.questionId,
        answer_id: this.answerIds,
      });
    },
  },

  watch: {
    questionList: function() {
      if (this.questionList.length) {
        this.questionId = this.questionList.map((item) => item.id);
      }
      for (let i = 1; i < this.questionList.length; i++) {
        this.answerIds.push(null);
        if (!this.questionList.choice_answer) {
          this.essayIndex = i;
        }
      }
    },
    answerAlls: function() {
      this.answerAll = this.answerAlls.filter(
        (item) =>
          item.answer_user[item.answer_user.length - 1].answer !== null ||
          item.answer_user[item.answer_user.length - 1].answer_text !== null
      );
      this.isLoadingButton = false;
      this.showAnswer = true;
    },
  },
};
</script>

<style scoped>
.modals {
  width: 480px;
  height: 285px;
  background: white;
  padding: 30px;
}
.container {
  width: 85%;
  margin: 20px 10px 0 20px;
}
.question {
  margin: 20px 0 20px 0;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.modal-ans {
  height: 478px;
  width: 480px;
  background-color: white;
}

.button-ans-left-1 {
  cursor: pointer;
}
.box-ans-text {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-ans-text7 {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #4caf50;
  justify-content: space-between;
}

.modal-ans-body {
  margin-left: 35px;
}

.modal-ans-header {
  width: 100%;
  height: 58px;
  background-color: #7957d5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
}

.box-ans-con {
  width: 409px;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaaaaa;
  margin-top: 10px;
}

.box-ans-2 {
  width: 409px;
  height: 150px;
  border: 1px solid #aaaaaa;
  margin-top: 10px;
}

.box-ans {
  width: 401px;
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.modal-title-1 {
  margin-top: 26px;
  font-size: 11px;
  font-weight: 700;
}

.modal-title-2 {
  font-size: 13px;
  margin-top: 5px;
}

.modal-title-3 {
  font-size: 12px;
  color: #00c372;
  margin-top: 16px;
}

.modal-title-4 {
  font-size: 12px;
  color: #ec1c24;
  margin-top: 16px;
}

.button-ans {
  margin-top: 21px;
  width: 409px;

  display: flex;
  justify-content: space-between;
}

.button-ans-left {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.button-ans-left-2 {
  font-size: 12px;
  height: 30px;
  width: 108px;
  margin-left: 20px;
}

.button-ans-left-done {
  font-size: 12px;
  height: 30px;
  width: 108px;
  margin-left: 200px;
}

.button-ans-right {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.your-ans {
  height: 20px;
  border-radius: 10px;
  width: 100px;
  background-color: #f46a6a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-ans-right-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 21px;
  background-color: rgba(121, 87, 213, 0.23);
  border-radius: 2px;
  margin-right: 4px;
}

.submit-buttons {
  margin-bottom: 30px;
}
</style>
