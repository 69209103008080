<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style>
#app {
  font-family: Mulish, Helvetica, Arial, sans-serif;
  font-weight: 400;
  height: 100vh;
  width: 100%;
  margin: 0;
}

.fs36 {
  font-size: 36px;
}
.fs30 {
  font-size: 30px;
}
.fs24 {
  font-size: 24px;
}
.fs20 {
  font-size: 16px;
}
.fs16 {
  font-size: 16px;
}
.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}
.bold {
  font-weight: 700;
}
select {
  /* color: white; */
  width: 60px;
  height: 30px;
  border-radius: 5px;
  background-color: white;
}

option {
  color: black;
}
</style>
