<template>
  <div class="counter-container" :style="style">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9H7C4.79086 9 3 10.7909 3 13C3 15.2091 4.79086 17 7 17H17C19.2091 17 21 15.2091 21 13C21 10.7909 19.2091 9 17 9Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <rect
        x="6.5"
        y="11.5"
        width="2"
        height="3"
        rx="0.5"
        :stroke="iconColor"
      />
      <rect
        x="11.5"
        y="11.5"
        width="2"
        height="3"
        rx="0.5"
        :stroke="iconColor"
      />
      <path d="M16 11.5H17.5V15" :stroke="iconColor" />
      <path d="M12 5V8" :stroke="iconColor" stroke-width="2" />
      <path
        d="M9 5H15"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M9 20H15"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 10,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    style() {
      return "background-color: " + this.background;
    },
  },
};
</script>

<style>
.counter-container {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: 0 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
