var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"v-if":_vm.userAnswerList}},[_c('div',{staticClass:"fs24 bold"},[_vm._v("Answer")]),_vm._l((_vm.userAnswerList),function(item){return _c('div',{key:item.id,staticClass:"question-item"},[_c('div',{staticClass:"fs14 question"},[_vm._v(_vm._s(item.question))]),_c('div',{staticClass:"box-ans-con"},[_c('div',{staticClass:"box-ans"},[(item.choice_answer && item.choice_answer.length)?_c('div',{staticClass:"box-ans-text",style:(_vm.boxStyle1(item))},[_vm._v(" "+_vm._s(item.choice_answer && item.choice_answer[0] && item.choice_answer[0].answer)+" "),(item.choice_answer)?_c('div',{staticClass:"your-ans",style:(_vm.ansStyle1(item))},[_vm._v(" Your Answer ")]):_vm._e()]):_c('div',{staticClass:"box-ans-text",style:(_vm.boxStyle1(item))},[_vm._v(" "+_vm._s(item.answer_user && item.answer_user[0] && item.answer_user[item.answer_user.length - 1].answer_text)+" "),(
              item.answer_user[0] &&
                item.answer_user[item.answer_user.length - 1].answer_text &&
                item.answer_user[item.answer_user.length - 1].answer_text !==
                  item.is_answer_text
            )?_c('div',{staticClass:"your-ans",style:(_vm.ansStyle1(item))},[_vm._v(" Your Answer ")]):_vm._e()])]),_c('div',{staticClass:"box-ans"},[(item.choice_answer && item.choice_answer.length)?_c('div',{staticClass:"box-ans-text",style:(_vm.boxStyle2(item))},[_vm._v(" "+_vm._s(item.choice_answer && item.choice_answer[1] && item.choice_answer[1].answer)+" "),(item.choice_answer)?_c('div',{staticClass:"your-ans",style:(_vm.ansStyle2(item))},[_vm._v(" Your Answer ")]):_vm._e()]):(
            item.answer_user[0] &&
              item.answer_user[item.answer_user.length - 1].answer_text &&
              item.answer_user[item.answer_user.length - 1].answer_text ==
                item.is_answer_text
          )?_c('div',{staticClass:"box-ans-text-true"},[_vm._v(" "+_vm._s(item.is_answer_text)+" ")]):_vm._e()])]),_c('img',{attrs:{"src":require("../assets/line-hor.png")}})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }