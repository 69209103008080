import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import assignment from "./modules/assignment";
import disease from "./modules/disease";
import cases from "./modules/cases";
import slide from "./modules/slide";
import question from "./modules/question";
import annotation from "./modules/annotation";
import answer from "./modules/answer";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    assignment,
    disease,
    cases,
    slide,
    question,
    annotation,
    answer,
  },
  strict: debug,
});
