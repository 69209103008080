<template>
  <div class="login page">
    <div class="containers">
      <div class="left-sidebar" id="left-sidebar">
        <SlideList :state="state" />
      </div>
      <div class="image-container" id="image-container">
        <Toolbar
          :state="state"
          :zoomVal="state.zoomVal"
          :showRightSibebars="showRightSidebars"
          @showRightBar="showRightBar"
          @changeZoom="changeZoom"
        />
        <div v-for="list in slideList" :key="list.id">
          <ImageView
            v-if="loadImage(list.id)"
            v-show="list.id == state.selectedSlide"
            :slideId="list.id"
            :imageUrl="list.slide"
            :thumbnailUrl="list.thumbnail"
            :zoomSize="zoomSize"
            :state="state"
            :annotColor="state.annotColor"
            :showRightSidebar="state.showRightSidebar"
            :clickDelete="state.clickDelete"
            :brightness="enhanceState.brightness"
            :contrast="enhanceState.contrast"
          />
        </div>
      </div>
      <div class="right-sidebar" id="right-sidebar">
        <div @click="closeRightSidebar" class="closeButton">
          <img src="../assets/arrow-box.png" class="toolbar-icon" />
        </div>
        <EnhanceImage v-show="state.showEnhance" :enhanceState="enhanceState" />
        <QuestionList
          :state="state"
          v-show="
            !state.showEnhance &&
              assignmentStatus !== 'completed' &&
              userAnswerList.question &&
              userAnswerList.question[0] &&
              userAnswerList.question[0].answer_user.length === 0 &&
              state.isAssignmentSubmited === false
          "
        />
        <AnswerList
          v-if="
            !state.showEnhance &&
              (assignmentStatus === 'completed' ||
                (userAnswerList.question &&
                  userAnswerList.question[0] &&
                  userAnswerList.question[0].answer_user.length >= 1) ||
                state.isAssignmentSubmited === true)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "../components/Toolbar.vue";
import QuestionList from "../components/QuestionList.vue";
import AnswerList from "../components/AnswerList.vue";
import SlideList from "../components/SlideList.vue";
import EnhanceImage from "../components/EnhanceImage.vue";
import ImageView from "../components/ImageView.vue";

import { mapState } from "vuex";

export default {
  components: {
    Toolbar,
    QuestionList,
    AnswerList,
    SlideList,
    EnhanceImage,
    ImageView,
  },
  data() {
    return {
      state: {
        showEnhance: false,
        annotColor: [76, 175, 80, 255],
        enableCreate: false,
        selectedSlide: 0,
        clickDelete: true,
        showRightSidebar: true,
        isAssignmentSubmited: false,
        zoomVal: -2,
      },
      zoomSize: 0,
      closeEnhances: true,
      showRightSidebars: true,
      enhanceState: {
        brightness: 0,
        contrast: 0,
      },
      visitedSlide: [],
      open: false,
    };
  },
  methods: {
    showRightBar(par) {
      if (par) {
        this.openRightSidebar();
      } else {
        this.closeRightSidebar();
        this.showRightSidebars = false;
      }
    },
    changeZoom(val) {
      this.zoomSize = val;
    },
    loadImage: function(id) {
      if (this.visitedSlide.indexOf(id) > -1) {
        return true;
      }
      if (id == this.state.selectedSlide) {
        this.visitedSlide.push(id);
        return true;
      }
      return false;
    },
    openRightSidebar: function() {
      document.getElementById("right-sidebar").style.margin = "0";
      document.getElementById("image-container").style.width = "60%";
      document.getElementById("toolbar").style.width = "60%";
      this.showRightSidebars = true;
    },
    closeRightSidebar: function() {
      document.getElementById("right-sidebar").style.margin = "0 -20% 0 0";
      document.getElementById("image-container").style.width = "80%";
      document.getElementById("toolbar").style.width = "80%";
      this.showRightSidebars = false;
    },
  },
  computed: {
    ...mapState({
      slideList: (state) => state.slide.slideList,
      assignmentStatus: (state) =>
        state.answer.userAnswerList.case &&
        state.answer.userAnswerList.case[0].assigment.status,
      userAnswerList: (state) => state.answer.userAnswerList,
    }),
  },
  created() {
    this.$store.dispatch("getUserAnswer", this.$route.params.case_id);
  },
  watch: {},
};
</script>

<style scoped>
.test {
  font-size: 60px;
}
.containers {
  display: flex;
}

.image-container {
  height: 100vh;
  width: 60%;
  transition: 0.5s;
}

.right-sidebar {
  width: 20%;
  height: 100vh;
  right: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  border-left: 1px solid #d1d9e4;
  overflow: scroll;
  transition: 0.5s;
}

.left-sidebar {
  width: 20%;
  border-right: 1px solid #d1d9e4;
  height: 100vh;
  overflow: scroll;
}

.closeButton {
  font-size: 20px;
  margin: 20px 0 0 80%;
}

.left-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.left-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.right-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.right-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
