import axios from "axios";

const BASE_URL = "https://merck.neurabot.ai/";

const state = {
  questionList: [],
  answer: [],
};

const actions = {
  getFirstQuestion: ({ commit }, caseId) => {
    commit("setAnswer");
    axios({
      method: "get",
      url: BASE_URL + `api/list-question-parent/` + caseId + "/",
    })
      .then((resp) => commit("setFirstQuestion", resp.data.question))
      .catch((err) => {
        console.log(err);
      });
  },
  getNextQuestion: ({ commit }, param) => {
    commit("resetNextQuestion");
    axios({
      method: "get",
      url: BASE_URL + `api/list-questions/` + param.id + "/",
      params: {
        type_answer: param.type,
      },
    })
      .then((resp) => commit("setNextQuestion", resp.data.question))
      .catch((err) => {
        console.log(err);
      });
  },
  submitAnswer: ({ commit }, param) => {
    axios({
      method: "post",
      url: BASE_URL + `api/submit-answer/`,
      data: {
        case_id: param.case_id,
        question_id: param.question_id,
        answer_id: param.answer_id,
      },
    })
      .then((resp) => commit("setAnswer", resp.data))
      .catch((err) => {
        console.log(err);
      });
  },
};

const mutations = {
  setFirstQuestion: (state, resp) => {
    state.questionList = resp;
    state.questionList.splice(1, state.questionList.length - 1);
  },
  setNextQuestion: (state, resp) => {
    state.questionList.splice(1, state.questionList.length - 1);
    state.questionList = state.questionList.concat(resp);
  },
  resetAnswer: (state) => {
    state.answer = [];
  },
  resetNextQuestion: (state) => {
    state.questionList.splice(1, state.questionList.length - 1);
  },
  setAnswer: (state, resp) => {
    state.answer = resp;
  },
};

export default {
  state,
  actions,
  mutations,
};
