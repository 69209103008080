import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Login from "./pages/Login.vue";
import ListAssignment from "./pages/ListAssignment.vue";
import ListDisease from "./pages/ListDisease.vue";
import ListCase from "./pages/ListCase.vue";
import QuestionPage from "./pages/QuestionPage.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Login,
      meta: {
        disableIfLoggedIn: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        disableIfLoggedIn: true,
      },
    },
    {
      path: "/assignment",
      name: "assignment",
      component: ListAssignment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/assignment/:assignment_id",
      name: "disease",
      component: ListDisease,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/assignment/:assignment_id/:disease_id",
      name: "case",
      component: ListCase,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/assignment/:assignment_id/:disease_id/:case_id",
      name: "question",
      component: QuestionPage,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    if (to.matched.some((record) => record.meta.disableIfLoggedIn)) {
      if (store.getters.isLoggedIn) {
        next("/assignment");
        return;
      }
    }
    next();
  }
});

export default router;
