import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import VueRouter from "vue-router";
import router from "./routes";
import store from "./store";
import Axios from "axios";

Vue.use(VueRouter);
Vue.use(Buefy);

const token = localStorage.getItem("user-token");
if (token) {
  Axios.defaults.headers.common["Authorization"] = "Token " + token;
}

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router: router,
  store,
}).$mount("#app");
